import React from "react";
import { Loading } from "components/loading";
import { useAdminContent } from "context/admin-content";
import { useRiskReport } from "context/risk-report";
import { useRiskProfile } from "context/risk-profile";
import { usePatientInterest } from "context/patient-interest";
import { SectionHeading } from "./section-heading";
import { RiskResultsSection } from "./sections/risk-results";
import { NextStepsSection } from "./sections/next-steps";
import { LearnMoreSection } from "./sections/learn-more";
import { Content } from "../styles";
import { GlobalStyle, PageHeading } from "./styles";

export function PatientResultsDisplay2() {
  const adminData = useAdminContent();
  const { riskData } = useRiskProfile();
  const { isLoading: reportLoading } = useRiskReport();

  const isLoading = adminData?.isLoading || reportLoading || !riskData;

  if (isLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  const sections = [
    {
      title: "Risk Results",
      content: <RiskResultsSection />,
    },
    ...(riskData.videoEnabled
      ? [
          {
            title: "Learn More",
            content: <LearnMoreSection videoUrl={riskData.videoUrl} />,
          },
        ]
      : []),
    ...(riskData.showScheduler
      ? [
          {
            title: "Next Steps",
            content: <NextStepsSection html={riskData.bodyHtml} />,
          },
        ]
      : []),
    // { title: "Share", content: null },
  ];

  return (
    <>
      <GlobalStyle />
      <PageHeading>Patient Results Page</PageHeading>
      {sections.map((section, index) => (
        <React.Fragment key={section.title}>
          <SectionHeading number={index + 1} title={section.title} />
          {section.content}
        </React.Fragment>
      ))}
    </>
  );
}
