import styled from "styled-components";
import { Typography } from "storybook/components/typography";
import { defaultTheme } from "storybook/themes/default";
import { mobile } from "styles/breakpoints";
const { colors } = defaultTheme;

export const HeadingContainer = styled.div`
  margin-top: 16px;
  background-color: ${colors.zircon};

  @media (min-width: 1200px) {
    background-color: transparent;
  }
`;

export const SectionNumber = styled(Typography).attrs({
  // @ts-ignore cannot resolve against default theme
  theme: "default",
})`
  // Temporary override until new Typography designs are implemented with Noto Sans font
  && {
    font-weight: 700 !important;
  }
  margin-right: 0.5rem;
`;

export const StyledSection = styled.section`
  height: 77px;
  padding: 0 16px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  && {
    p {
      font-size: 18px;
      font-weight: 700;
    }
  }

  @media (min-width: ${mobile}px) {
    height: 60px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (min-width: 1200px) {
    width: 1168px;
    background-color: ${colors.zircon};
  }
`;
