import { useState, useCallback } from "react";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import GetAppIcon from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import { Button } from "storybook/components/button";
import { Video } from "components/video";
import { Loading } from "components/loading";
import { InterestModal } from "components/interest-modal";
import { useAdminContent, useFeatureFlags } from "context/admin-content";
import { useScrollbar } from "context/scrollbar";
import {
  ModalType,
  usePatientInterest,
  PatientInterestProvider,
} from "context/patient-interest";
import { useRiskProfile } from "context/risk-profile";
import { RiskReportProvider, useRiskReport } from "context/risk-report";
import { RiskProfileProvider } from "context/risk-profile";
import { downloadPdf } from "helpers/download-pdf";
import { EmailModal } from "./email-modal";
import { Completed } from "./completed";
import {
  Content,
  FooterBar,
  LeftColumn,
  Scrollable,
  RightColumn,
  MobileVideo,
  CopyContainer,
  FooterContent,
  VideoContainer,
  ColumnsContainer,
  ReportContainer,
  ErrorContainer,
} from "./styles";

import { PatientResultsDisplay2 } from "./patient-results-display-2";

function PatientResultsDisplay() {
  const adminData = useAdminContent();
  const [showModal, setShowModal] = useState(false);
  const { scrollbarVisible, scrollbarWidth } = useScrollbar();
  const {
    error,
    reportHtml,
    refetchRiskReport,
    isLoading: reportLoading,
    reportPdf,
  } = useRiskReport();
  const { modalType, interestLevel, setModalType } = usePatientInterest();
  const { riskData, isLoading: riskProfileLoading } = useRiskProfile();

  const isLoading = adminData?.isLoading || reportLoading || riskProfileLoading;

  const handleInterestClick = useCallback(() => {
    setModalType(ModalType.INITIAL);
  }, [setModalType]);

  const handleEmailClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseClick = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleDownloadClick = useCallback(() => {
    downloadPdf({ reportPdf });
  }, [reportPdf]);

  const showVideo = riskData?.videoEnabled;

  const params = new URLSearchParams(window.location.search);
  const hideInterest = params.get("hideInterest");

  return isLoading ? (
    <Content>
      <Loading />
    </Content>
  ) : (
    <Content>
      <ColumnsContainer>
        <LeftColumn>
          <Scrollable>
            <CopyContainer role="article">
              {HtmlParser(riskData?.bodyHtml)}
            </CopyContainer>
            {showVideo && (
              <MobileVideo>
                <Video src={riskData?.videoUrl} label="results video mobile" />
              </MobileVideo>
            )}
            <ReportContainer>
              {error && (
                <ErrorContainer>
                  <p>There was an error loading your risk assessment.</p>
                  <Button
                    theme="default"
                    variant="outlined"
                    onClick={refetchRiskReport}
                  >
                    Try Again
                  </Button>
                </ErrorContainer>
              )}
              <ReportContainer role="article">
                {HtmlParser(reportHtml)}
              </ReportContainer>
              {showModal && <EmailModal onClose={handleCloseClick} />}
            </ReportContainer>
          </Scrollable>
          <FooterBar
            $single={!showVideo}
            $scrollbarWidth={scrollbarWidth}
            $scrollbarVisible={scrollbarVisible}
          >
            <FooterContent
              $single={!showVideo}
              $scrollbarWidth={scrollbarWidth}
              $scrollbarVisible={scrollbarVisible}
            >
              <Button
                theme="default"
                variant="outlined"
                onClick={handleDownloadClick}
                startIcon={<GetAppIcon color="primary" />}
              >
                Download PDF
              </Button>
              <Button
                theme="default"
                variant="outlined"
                onClick={handleEmailClick}
                startIcon={<EmailIcon color="primary" />}
              >
                Email Results
              </Button>
              {riskData?.showScheduler &&
                interestLevel === null &&
                !hideInterest && (
                  <Button
                    theme="default"
                    color="default"
                    variant="contained"
                    onClick={handleInterestClick}
                  >
                    Mark Interest
                  </Button>
                )}
            </FooterContent>
          </FooterBar>
        </LeftColumn>
        {showVideo && (
          <RightColumn $single={!showVideo}>
            <VideoContainer>
              <Video src={riskData?.videoUrl} label="results video desktop" />
            </VideoContainer>
          </RightColumn>
        )}
      </ColumnsContainer>
      {modalType && <InterestModal riskResults={riskData} />}
    </Content>
  );
}

export default function PatientResultsPage() {
  const adminData = useAdminContent();
  const featureFlags = useFeatureFlags();
  const surveyType = localStorage.getItem("surveyType");

  if (!adminData || adminData.isLoading) {
    return <Loading />;
  }

  if (
    featureFlags?.disablePatientApprovalForRemoteScreenings === false &&
    surveyType === "remote"
  ) {
    return <Completed />;
  }

  const newResultsLandingPageEnabled =
    localStorage.getItem("ciq_new_results_landing_page") === "true";

  return (
    <PatientInterestProvider>
      <RiskReportProvider>
        <RiskProfileProvider>
          {newResultsLandingPageEnabled ? (
            <PatientResultsDisplay2 />
          ) : (
            <PatientResultsDisplay />
          )}
        </RiskProfileProvider>
      </RiskReportProvider>
    </PatientInterestProvider>
  );
}
