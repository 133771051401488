import { Typography } from "storybook/components/typography";
import { HeadingContainer, StyledSection, SectionNumber } from "./styles";

type Props = {
  number: number;
  title: string;
};

export function SectionHeading({ number, title }: Props) {
  return (
    <HeadingContainer>
      <StyledSection>
        <SectionNumber variant="h4" component="h2" color="governorBay">
          {number}
        </SectionNumber>
        <Typography theme="default" variant="body1" color="governorBay">
          {title}
        </Typography>
      </StyledSection>
    </HeadingContainer>
  );
}
